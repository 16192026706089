import axios from 'axios';
import Cookies from 'js-cookie';
import domain from '../../index';
import ProjectConfigConfirmationModal from '../components/newProjectPage/ProjectConfigConfirmationModal';
import React, { Component } from 'react';
import TopMenuBar from '../../common/components/TopMenuBar';
import UnconfiguredProjectIDForm from '../../common/components/UnconfiguredGroupIDForm';
import UnconfiguredProjectPreview from '../components/newProjectPage/UnconfiguredProjectPreview';

/**
 * @brief Page used to allow students to configure 
 *        a personal project to IFS.
 */
class NewProjectPage extends Component {
  state = {
    previewId: '',
    showPreview: false,
    continueFromPreview: false,
    project: {},
    cannotBeSaved: false,
    saving: false,
    errorPosting: false,
  };

  /**
   * Retrieves the preview of the unconfigured project from the server
   * and changes the state of the page accordingly
   * @param previewId {string}
   * @stateChange preciewId {string}
   * @stateChange showPreview {bool}
   */
  showGitlabPreview = (previewId) => {
    this.setState(
      (state) => ({
        previewId,
      }),
      async () => {
        try {
          let response = await axios.get(`${domain.serverBase}/student/projects/unconfigured/${this.state.previewId}?token=` +
                                         Cookies.get('token'));

          this.setState({ project: response.data });
        } catch (err) {
          this.setState({ project: err.response.data });
        } finally {
          this.setState({ showPreview: true });
        }
      }
    );
  };

  /**
   * Changes which feedback options are selected for a project being configured
   * and changes the state of page accordingly
   * @param projectName {string}
   * @param feedbackOptions { {name: string, id: number, selected: bool}[] }
   * @stateChange project { name: string, 
   *                        id: number, 
   *                        url: string, 
   *                        configuring: bool, 
   *                        chosenFeedback: {name: string, id: number, selected: bool}[] 
   *                      }  
   */
  handleProjectFeedbackOptionSelectionChange = (projectName, feedbackOptions) => {
    let curProject = this.state.project;
    curProject.name = projectName;
    curProject.chosenFeedback = feedbackOptions;  
    this.setState({ project: curProject });
  };

  /**
   * If the student wants to edit the project ID, change the state of 
   * page to allow them to do it.
   * @stateChange showPeview {bool}
   * @dtateChange cannotBeSaved {bool}
   * @stateChange continueFromPreview {bool}
   */
  editProjectId = () => {
    this.setState({ showPreview: false, 
                    cannotBeSaved: false, 
                    continueFromPreview: false 
                  });
  };

  /**
   * When logout button is clicked, return to the login page.
   * @note revoking the cookie doensn't seem to work as we want it to...
   */
  handleLogoutButton = () => {
    Cookies.remove('token');
    this.props.history.push('/');
  };

  /**
   * Changes whether a project is being configured or not within the projects held in the state
   * @param configState {bool}
   * @stateChange project { name: string, 
   *                        id: number, 
   *                        url: string, 
   *                        configuring: bool, 
   *                        chosenFeedback: {name: string, id: number, selected: bool}[] 
   *                      }  
   */
  handleProjectConfigStateChange = (configState) => {
    let curProject = this.state.project;
    curProject.configuring = configState;
    this.setState({ project: curProject });
  };

  /**
   * Handle if an student chooses to configure the personal project and checks
   * if the configuration is valid
   * @stateChange cannotBeSaved {bool}
   * @stateChange saving {bool}
   */
  handleAddingProjectConfiguration = () => {
    let project = this.state.project;

    if (project.configuring === false || !project.chosenFeedback || project.chosenFeedback.length === 0) {
      this.setState({ cannotBeSaved: true });
    } else {
      this.setState({ cannotBeSaved: false });
      this.setState({ saving: true });
    }
  };

  /**
   * Cancels configuraton by changing page
   * @param event
   * @stateChange saving {bool}
   */
  handleCancellingConfig = (event) => {
    event.preventDefault();
    this.setState({ saving: false });
  };

  /**
   * Adds the personal project to students IFS account and redirects to 
   * Student Page where project should now be viewable if no error occured.
   * @param {event} event
   * @stateChane errorposting {bool}
   * @note as of now, all personal projects will be stored under a "personal" group/course
   */
  handleSubmitConfig = async (event) => {
    event.preventDefault();

    let project = this.state.project;
    let selectedFeedback = [];

    for (const feedback of project.chosenFeedback) {
      selectedFeedback.push({ name: feedback.name });
    }

    let configuration = {
      projectId: parseInt(this.state.previewId),
      projectName: this.state.project.name,
      groupName: "Personal",
      feedbackOptions: selectedFeedback,
    };

    try {
      await axios.post(`${domain.serverBase}/student/projects/configure?token=` + 
                        Cookies.get('token'),
                        configuration
      );

      this.props.history.push('/student');
    } catch (err) {
      this.setState({ errorPosting: true });
    }
  };

  render() {
    return (
      <>
        <header style={{ backgroundColor: 'rgb(173, 205, 255)' }}>
          <TopMenuBar 
            onLogoutClick={this.handleLogoutButton}
            isInstructor={false} />
        </header>
          <div className="mt-3 mx-auto" style={{ width: '80%' }}>
            <div
              className="d-inline-flex border rounded-top border-bottom-0 pt-3 pl-3 pr-3 pb-2"
              style={{ backgroundColor: 'rgb(239, 241, 245)' }}
            >
            <h4>New Project</h4>
          </div>

          <div
            className="d-flex flex-column border pt-3 pl-3 pr-3 pb-2"
            style={{
              borderRadius: '0px 5px 5px 5px',
              backgroundColor: 'rgb(239, 241, 245)',
            }}
          >
            <UnconfiguredProjectIDForm
              showPreviewState={this.state.showPreview}
              showPreview={this.showGitlabPreview}
              groupOrProjectLabel="Project"
            />

            {/* Preview of gitlab group based on entered gitlab group id */}
            {this.state.showPreview && (
              <UnconfiguredProjectPreview
                continueFromPreview={this.state.continueFromPreview}
                project={this.state.project}
                onEditProjectIdClick={this.editProjectId}
                onFeedbackOptionSelectionChange={this.handleProjectFeedbackOptionSelectionChange}
                onConfiguredStateChange={this.handleProjectConfigStateChange}
                handleAddingProjectConfiguration={this.handleAddingProjectConfiguration}
              />
            )}

            {/*Show message to user if the configuration is invalid and cannot be saved*/}
            {this.state.cannotBeSaved && (
              <div className="mt-2" style={{ color: 'red' }}>
                <b>
                  <div>The current configuration cannot be saved.</div>
                  <div>To save the configuration, at least one feedback type must be selected.</div>
                </b>
              </div>
            )}

            {/*Show the confirmation modal if the configuration can be submitted*/}
            {this.state.saving && (
              <ProjectConfigConfirmationModal
                onCancelConfig={this.handleCancellingConfig}
                onSubmitConfig={this.handleSubmitConfig}
              />
            )}

            {/*Show message to user if the configuration fails to be posted */}
            {this.state.errorPosting && (
              <div className="mt-2" style={{ color: 'red' }}>
                <b>
                  <div>The current configuration could not be saved.</div>
                  <div>Please try again or contact an administrator if the error continues.</div>
                </b>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default NewProjectPage;
