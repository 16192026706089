import axios from 'axios';
import Cookies from 'js-cookie';
import domain from '../../index.js'
import qs from 'qs';
import React, { Component } from 'react';

/**
 * @brief The redirect page
 */
class RedirectPage extends Component {
  state = {};

  /**
   * redirects to valid page based on token.
   * @note Method gets invoked right after the component has been mounted.
   */
   
   
  async componentDidMount() {
    Cookies.set(
      'token',
      qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).token
    );
    
    const isInstructor = await axios
      .get(`${domain.serverBase}/user/isInstructor?token=` + Cookies.get('token'))
      .then((response) => response.data);

      isInstructor 
        ? this.props.history.push('/instructor/courses/') 
        : this.props.history.push('/student');
  }

  render() {
    return <div>Redirecting...</div>;
  }
}

export default RedirectPage;
