import React, { Component } from 'react';

/**
 * @brief Loading screen that runs while necessary data is being fetched
 *        from server.
 */
class Loading extends Component {
  render() {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h1 className="text-primary">Immediate Student Feedback</h1>
        <h2>Loading...</h2>
        <div className="spinner-border text-primary" />
      </div>
    );
  }
}

export default Loading;
