import FeedbackOption from './FeedbackOption';
import React, { Component } from 'react';

/**
 * @brief All feedback option that can be selected when 
 *        instructor configures a single project for a group
 *        to add to IFS.
 */
class FeedbackOptions extends Component {
  render() {
    const { project, onOptionChange, feedbackOptions } = this.props;

    return (
      <div className="d-inline-flex flex-row align-items-center">
        <b className="mr-2">Types of Feedback:</b>

        {/*Each type of feedback gets created as an option to select*/}
        {feedbackOptions.map((option) => (
          <FeedbackOption
            key={project.name + option.id}
            option={option}
            onOptionChange={onOptionChange}
            project={project}
          />
        ))}
      </div>
    );
  }
}

export default FeedbackOptions;
