import FeedbackConfigForm from '../newProjectPage/FeedbackConfigForm';
import FeedbackItem from '../../../common/components/FeedbackItem';
import React, { Component } from 'react';
import styled from 'styled-components';

const FormContainer = styled.div`
  border-radius: 5px;
  padding: 15px;
  background-color: white;
`;

/**
 * @brief Loads feedback information for the selected course, 
 *        project and branch given the most recent commit 
 */
class CommitFeedback extends Component {
  state = { allExpanded: false };

  /**
   * Show all feedback information by updating state
   * @stateChange allExpanded {bool}
   */
  expandAll = () => {
    this.setState({ allExpanded: true, });
  };

  /**
   * Hide all feedback information by updating state
   * @stateChange allExpanded {bool}
   */
  collapseAll = () => {
    this.setState({ allExpanded: false, });
  };

  /**
   * Checks the most recent commit to see if CI pipeline is running 
   * or if it finished running.
   * @return {bool}
   * @note if there is no pipeline, assume .yml was just added.
   * @note if pending or running, this indicates that beedback is being generated
   */
  isPipelineRunning = () => {
    const { commit } = this.props;

    if (
      commit.pipelineStatus === 'no pipeline' ||
      commit.pipelineStatus === 'running' ||
      commit.pipelineStatus === 'pending'
    ) {
      return true;
    }

    return false;
  };

  /**
   * Checks the most recent commit to see whetner CI pipeline succeded or not.  
   * @return {bool}
   * @note pipeline is considered "failed" if it is still running
   */
  isPipelineFailed = () => {
    const { commit } = this.props;

    if (!this.isPipelineRunning() && commit.pipelineStatus !== 'success') {
      return true;
    }

    return false;
  };

  render() {
    const { feedbackOptions, onSaveFeedbackConfiguration, commit } = this.props;

    let feedbackMap = new Map();

    if (commit.feedback) {
      for (const feedback of commit.feedback) {
        feedbackMap.set(feedback.title, feedback);
      }
    }

    return (
      <div
        className="card-body pt-3 pb-0"
        style={{ backgroundColor: 'rgb(239, 241, 245)' }}
      >
        <h4 className="mb-3" style={{ fontWeight: 'bold' }}>Commit Feedback</h4>

        {/*Only attempt to display feedback if pipeline has successfully completed*/}
        {commit.pipelineStatus === 'success' && (
          <div data-testid="feedback">
            <FeedbackConfigForm
              feedbackOptions={feedbackOptions}
              onSaveFeedbackConfiguration={onSaveFeedbackConfiguration}
            />
            {feedbackOptions
              .filter((feedbackOption) => feedbackOption.selected === true)
              .map((feedbackOption) => (
                <div key={feedbackOption.name}>
                  <FormContainer className="border my-3">
                    <h5>{feedbackOption.name}:</h5>

                    {/*If feedback with the feedback name was retrieved by the server, and has at least 1 feedback item, display feedback*/}
                    {feedbackMap.has(feedbackOption.name) &&
                      feedbackMap.get(feedbackOption.name).feedbackItems
                        .length > 0 && (
                        <div data-testid="feedbackAndExpandButtons">
                          <button className="btn btn-primary" onClick={this.expandAll}>Expand All</button>
                          <button className="btn btn-secondary ml-3" onClick={this.collapseAll}>Collapse All</button>
                          
                          <div className="accordion mt-2" id="feedbackAccordian">
                            {feedbackMap
                              .get(feedbackOption.name)
                              .feedbackItems.map((feedbackItem, i) => (
                                <FeedbackItem
                                  feedbackItem={feedbackItem}
                                  key={i}
                                  count={i + 1}
                                  expanded={this.state.allExpanded}
                                />
                              ))}
                          </div>
                        </div>
                      )}

                    {/*If feedback with the feedback name was retrieved by the server, and has no feedback items, display this message*/}
                    {feedbackMap.has(feedbackOption.name) &&
                      feedbackMap.get(feedbackOption.name).feedbackItems
                        .length === 0 && (
                        <div data-testid="noFeedbackMessage">
                          Great job! You did not receive any feedback of this feedback type.
                        </div>
                      )}

                    {/*If feedback with the feedback name was not retrieved by the server, output this message*/}
                    {!feedbackMap.has(feedbackOption.name) && (
                      <div data-testid="feedbackTypeNotFoundMessage">
                        Feedback of this type could not be generated for this commit.
                      </div>
                    )}
                  </FormContainer>
                </div>
              ))}
          </div>
        )}

        {/*Output this message if feedback is currently being generated*/}
        {this.isPipelineRunning() && (
          <FormContainer
            className="border my-3"
            data-testid="pipelineRunningMessage"
          >
            The feedback for this commit is currently being generated.
            <div>
              Please check back a few minutes from the commit time and{' '}
              <b>refresh</b> the page to view your feedback results.
            </div>
          </FormContainer>
        )}

        {/*Treat all other statuses as failure*/}
        {this.isPipelineFailed() && (
          <FormContainer
            className="border my-3"
            data-testid="pipelineFailureMessage"
          >
            The feedback for this commit was unable to be generated.
          </FormContainer>
        )}
      </div>
    );
  }
}

export default CommitFeedback;
