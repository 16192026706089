import Chart from 'chart.js';
import React, { Component } from 'react';
import styled from 'styled-components';

let myChart;

const GraphContainer = styled.div`
  width: 60vw;
  height: 62vh;
  background: white;
  padding: 30px 20px 10px 20px;
  border-radius: 10px;
`

const BarChartBackgroundColours = [
  'rgba(255, 99, 132, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 206, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(255, 159, 64, 0.2)',
  'rgba(255, 99, 132, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 206, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(255, 159, 64, 0.2)'
]

const BarChartBorderColours = [
  'rgba(255,99,132,1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(255,99,132,1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)'
]

/**
 * @brief A bar chart representing the
 *        runner data
 */
export default class SummaryBarChart extends Component {
  chartRef = React.createRef();

  /**
   * Builds the bar chart when initially rendered.
   * @note Method gets invoked right after component has been rendered.
   */
   componentDidMount() {
    this.buildChart();
  }

  /**
   * Builds the bar chart when update.
   * @note Method gets invoked right after component has been updated.
   */
  componentDidUpdate() {
    this.buildChart();
  }

  /**
   * Build the bar chart
   */
  buildChart = () => {
    const { data, title } = this.props;
    const myChartRef = this.chartRef.current.getContext('2d');

    if (typeof myChart !== 'undefined') myChart.destroy();

    let datalabels = [];
    let datapoints = [];
    let i=0;

    for (let item of data) {
      datalabels[i] = item['name'];
      datapoints[i] = item['numStudents'];
      i++;
    }

    myChart = new Chart(myChartRef, {
      type: 'bar',
      data: {
        labels: datalabels,
        datasets:[{
          label: '# of student occurances',
          data: datapoints,
          backgroundColor: BarChartBackgroundColours,
          borderColor: BarChartBorderColours,
          borderWidth: 1
        }]
      },
      options: {
        title: {
          display: true,
          text: title
        },
        scales: {
          xAxes: [{
            ticks: {
              maxRotation: 90,
              minRotation: 80
            },
              gridLines: {
              offsetGridLines: true
            }
          },
          ],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              stepSize: 1
            }
          }]
        }
      }
    })
  }

  render() {
    return (
      <GraphContainer>
        <canvas
          id="SummaryBarChart"
          ref={this.chartRef}
        />
      </GraphContainer>
    )
  }
}
