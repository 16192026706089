import React, { Component } from 'react';

/**
 * @brief The model to confirm whether an instructor would like
 *        to submit their course configuration
 */
class CourseConfigConfirmationModal extends Component {
  render() {
    const { onCancelConfig, onSubmitConfig } = this.props;

    return (
      <>
        <div className="modal-backdrop fade show" style={{ zIndex: '1' }} />
        <div
          className="modal fade show"
          style={{
            display: 'block',
            zIndex: '2',
          }}
          id="staticBackdrop"
          data-backdrop="static"
          data-keyboard="false"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">Confirm Configuration</h5>

                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={onCancelConfig}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                The configuration of the course cannot be edited once submitted.
                Are you sure you would like to submit this configuration?
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onCancelConfig}
                >
                  Continue Editing
                </button>

                <button
                  type="button"
                  className="btn btn-success"
                  onClick={onSubmitConfig}
                >
                  Submit Configuration
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CourseConfigConfirmationModal;
