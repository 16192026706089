import axios from 'axios';
import Cookies from 'js-cookie';
import domain from '../../index';
import Insights from '../components/coursePage/Insights'
import React, { Component } from 'react';
import SelectionMenu from '../../common/components/SelectionMenu';
import styled from 'styled-components';
import TopMenuBar from '../../common/components/TopMenuBar';

const AppContainer = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  color: #222222;
`;

/**
 * @brief The instructors view of project data for a given course
 */
 class CoursePage extends Component {
  state = {
    coursesLoaded: false,
    selectedCourse: '',
    courses: [],
    projectsLoaded: false,
    selectedProject: '',
    projects: [],

    /* TODO: need to decide how to handle branches because there is currently no endpoint to get 'configured branches' (because they aren't)
     *   possible solutions: let the user type in the name for the branch
     *     assume master always
     *     cross reference all the available student projects and compile a list
     *     make the branches configurable when setting up a course and add an endpoint
     */
    branchesLoaded: true,
    selectedBranch: {'name': 'master', 'id': 'master'},
    branches: [{'name': 'master', 'id': 'master'}],

    studentsLoaded: false,
    selectedStudent: {'name': 'all students', 'id': 'all students'},
    students: [{'name': 'all students', 'id': 'all students'}],

    feedbackLoaded: false,
    selectedStudentFeedback: [],
    studentSummaryData: []
  };

  /**
   * Gets all configured courses from instructor's gitlab account 
   * and updates page state accordingly. 
   * @stateChange courses { {id: string, parentId: string, name: string}[] }
   * @stateChange selectedCourse {string}
   * @stateChange coursesLoaded {bool}
   */
  getCoursesFromServer = async () => {
    try {
      let response = await axios.get(`${domain.serverBase}/instructor/groups/configured?token=` +
                                      Cookies.get('token')
      );

      this.setState({ courses: response.data });
      this.setState({ selectedCourse: this.state.courses[0].id });
    } catch (err) {
      this.setState({ courses: err.response.data });
    } finally {
      this.setState({ coursesLoaded: true });
    }
  };

  /**
   * Gets all the configured projects from the instructors gitlab account 
   * for a selected course and updates the page state accordingly.
   * @stateChange projects { {id: string,  name: string}[] }
   * @stateChange selectedProject {string}
   * @stateChange projectsLoaded {bool}
   */
  getProjectsFromServer = async () => {
    try {
      let response = await axios.get(`${domain.serverBase}/instructor/groups/configured/${this.state.selectedCourse}/projects?token=` +
                                     Cookies.get('token'));

      this.setState({ projects: response.data });

      // this is for consistency with the other SelectionMenus -- projects is the only returned item without an id TODO - maybe change this later
      this.state.projects.forEach(item => {
        item.id = item.name;
      });

      this.setState({ selectedProject: this.state.projects[0].name });
    } catch (err) {
      this.setState({ projects: err.response.data });
    } finally {
      this.setState({ projectsLoaded: true });
    }
  };

  /**
   * Gets the configured branches from the student's gitlab account for a 
   * selected course and project and updates the state of the page sccordingly.
   * @stateChange branches {name: string}[]
   * @stateChange selectedBranch {string}
   * @stateChange branchesLoaded {bool}
   */
     getBranchesFromServer = async () => {
      
    };

  /**
   * Gets the number of students enrolled in the course
   * @stateChange studentSummaryData {numStudents: number}[]
   */
     getStudentSummaryDataFromServer = async () => {
      try {
        let response = await axios.get(`${domain.serverBase}/instructor/groups/configured/${this.state.selectedCourse}/numStudents?token=` +
                                       Cookies.get('token'));

        this.setState({ studentSummaryData: response.data });
      } catch (err) {
        this.setState({ studentSummaryData: err.response.data });
      }
    };

  /**
   * Gets the student overview for the couse.
   * @stateChange selectedStudentFeedback {topFeedbackItems {type: string, 
   *                                                         name: string, 
   *                                                         numStudents: number, 
   *                                                         percentStudents: number}[]
   *                                       overview {numStudents: number}[]
   *                                      }
   * @stateChange studentsLoaded {bool}
   * @note This is designed to potentially allow instructor to select a specific
   *       student to view feedback for. currently sets all studends to 'all students' 
   */
  getStudentFeedbackFromServer = async () => {
    try {
      let response = await axios.get(`${domain.serverBase}/instructor/groups/configured/${this.state.selectedCourse}/projects/${this.state.selectedProject}/branches/${this.state.selectedBranch.name}/overview?token=` +
                                     Cookies.get('token'));

      this.setState({ selectedStudentFeedback: response.data });
      this.setState({ studentsLoaded: true });
    } catch (err) {
      this.setState({ selectedStudentFeedback: err.response.data });
    } finally {
      this.setState({ feedbackLoaded: true })
    }
  };

  /**
   * Initializes the page with relevant courses, projects, 
   * branches, students and feedback data.
   * @note Method gets invoked right after the component has been mounted.
   */
  componentDidMount() {
    this.getCoursesFromServer().then(() => {
      if (this.state.courses.length > 0) {
        this.getProjectsFromServer().then(() => {
          this.getStudentSummaryDataFromServer().then(() => {
            this.getStudentFeedbackFromServer();
          });
        });
      }
    });
  }

  /**
   * Gets the project, branch, students, and feedback data for the newly selected course and updates the state
   * of the page accordingly.
   * @param event  
   * @stateChange selectedCourse {string}
   * @stateChange studentsLoaded {bool} 
   */
  handleCourseChange = (event) => {
    const selectedCourse = event.target.value;

    this.setState(
      {
        selectedCourse,
        studentsLoaded: false,
      },
      () => {
        this.getProjectsFromServer().then(() => {
            this.getStudentSummaryDataFromServer().then(() => {
              this.getStudentFeedbackFromServer();
            });
          });
      }
    );
  };

  /**
   * Gets the branch, students, and feedback data for the newly selected project and updates the state
   * of the page accordingly. Courses should remain unchange.
   * @param event  
   * @stateChange selectedProject {string}
   * @stateChange projectsLoaded {bool}
   */
  handleProjectChange = (event) => {
    const selectedProject = event.target.value;

    this.setState(
      {
        selectedProject,
        projectsLoaded: false,
      },
      () => {
        this.getProjectsFromServer();
      }
    );
  };

  /**
   * TODO; need strategy for providing branches to the instructor
   * handleBranchChange is triggered when the user selects a different branch from the BranchSelect
   *   dropdown and subsequently updates the UI
   */
  handleBranchChange = (event) => {
    const selectedBranch = event.target.value;

    this.setState(
      {
        selectedBranch,
        //branchesLoaded: false,
      },
      () => {
        // TODO: this.getBranches(); need some way to retrieve branches for a given project
      }
    )
  };

  /**
   * TODO: when selecting an individual student is implemented; currently not needed because overview is
   *   the current only option
   * handleStudentChange is triggered when the user selects a different student from the StudentSelect
   *   dropdown and subsequently updates the UI
   */
  handleStudentChange = (event) => {
    const selectedStudent = event.target.value;

    this.setState(
      {
        selectedStudent,
        // studentsLoaded: false,
      },
      () => {
        // TODO
      }
    )
  }

  /**
   * When logout button is clicked, return to the login page.
   * @note revoking the cookie doensn't seem to work as we want it to...
   */
  handleLogoutButton = () => {
    Cookies.remove('token');
    this.props.history.push('/');
  };

  render() {
    if (this.state.coursesLoaded === false) {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h1 className="text-primary">Immediate Student Feedback</h1>
          <h2>Loading...</h2>
          <div className="spinner-border text-primary" />
        </div>
      );
    } else {
      return (
        <>
          <header style={{ backgroundColor: 'rgb(173, 205, 255)' }}>
            <TopMenuBar 
              onLogoutClick={this.handleLogoutButton} 
              isInstructor={true}/>
          </header>
          <AppContainer>
            {this.state.courses.length > 0 && (
              <>
                <SelectionMenu
                  coursesLoaded={this.state.coursesLoaded}
                  selectedCourse={this.state.selectedCourse}
                  courses={this.state.courses}
                  onCourseChange={this.handleCourseChange}
                  projectsLoaded={this.state.projectsLoaded}
                  selectedProject={this.state.selectedProject}
                  projects={this.state.projects}
                  onProjectChange={this.handleProjectChange}
                  branchesLoaded={this.state.branchesLoaded}
                  selectedBranch={this.state.selectedBranch}
                  branches={this.state.branches}
                  onBranchChange={this.handleBranchChange}
                  studentsLoaded={this.state.studentsLoaded}
                  selectedStudent={this.state.selectedStudent}
                  students={this.state.students}
                  onStudentChange={this.handleStudentChange}
                  isInstructor={true}
                />
                <Insights
                  feedbackLoaded={this.state.feedbackLoaded}
                  selectedStudentFeedback={this.state.selectedStudentFeedback}
                  studentSummaryData={this.state.studentSummaryData}
                />
              </>
            )}
          </AppContainer>
        </>
      );
    }
  }
}

export default CoursePage;
