import React, { Component } from 'react';

/**
 * @brief display commit information for the selected course, project and
 *        branch given the most recent commit.
 */
class CommitInfo extends Component {
  render() {
    const { commit } = this.props;
    return (
      <>
        <h5>Commit URL: </h5>
        <a href={commit.URL} target="_blank" rel="noopener noreferrer">
          {commit.URL}
        </a>
        <br />
        <br />
        <h5>Commit Date & Time: </h5>
        {commit.date} at {commit.time}
      </>
    );
  }
}

export default CommitInfo;
