import React, { Component } from 'react';

/**
 * @brief form to enter gitlab project ID for a group to be configured.
 */
class UnconfiguredGroupIDForm extends Component {
  state = { previewId: '' };

  /**
   * Handle if a user hits enter when within the input field
   * @param {event} event
   * @stateChange previewId {string}
   */
  handleKeyDown = (event) => {
    const { showPreview } = this.props;

    if (event.key === 'Enter' && this.state.previewId.length > 0) {
      showPreview(this.state.previewId);
    }
  };

  /**
   * Handle if a user clicks the Preview button
   * @param {event} event
   * @stateChange previewId {string}
   */
  handleClick = (event) => {
    const { showPreview } = this.props;
    event.preventDefault();
    showPreview(this.state.previewId);
  };

  render() {
    const { showPreviewState, groupOrProjectLabel } = this.props;

    return (
      <div className="pl-3">
        <form>
          <div className="d-flex flex-row align-items-center justify-content-start form-group row">
            <label htmlFor="inputGroupId" className="col-form-label mt-1">
              <h5>Gitlab {groupOrProjectLabel} ID:</h5>
            </label>

            <div className="ml-2">
              <input
                type="text"
                className="form-control"
                id="inputGroupId"
                disabled={showPreviewState}
                value={this.state.previewId}
                onChange={(event) => this.setState({ previewId: event.target.value })}
                onKeyDown={this.handleKeyDown}
              />
            </div>

            {!showPreviewState && (
              <button
                className="btn btn-info ml-2"
                onClick={this.handleClick}
                disabled={this.state.previewId.length === 0}
              >
                Preview
              </button>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default UnconfiguredGroupIDForm;
