import React, { Component } from 'react';

/**
 * @brief The selection (dropdown) menu for selecting branches that the 
 *        student wishes to view the feedback for
 */
class BranchSelect extends Component {
  render() {
    const { selectedBranch, branches, onBranchChange } = this.props;

    return (
      <select
        className="rounded border"
        onChange={onBranchChange}
        value={selectedBranch.name}
        style={{ cursor: 'pointer', height: '40px' }}
      >
        {branches.map((branch) => (
          <option key={branch.name} value={branch.name}>
            {branch.name}
          </option>
        ))}
      </select>
    );
  }
}

export default BranchSelect;
