import React, { Component } from 'react';

/**
 * @brief Table that lists all currently configured courses for the instructor
 *        with a button to configure a new course
 */
class CourseList extends Component {
  render() {
    const { courses, onAddNewClick } = this.props;

    return (
      <table className="table mx-auto mt-3" style={{ width: '80%' }}>
        <thead>
          <tr>
            <th scope="col" className="border-0 pl-0">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <h4>Courses</h4>
                <button className="btn btn-success" onClick={onAddNewClick}>New Course</button>
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          {courses.length === 0 && (
            <tr>
              <td className="pl-0">You do not currently have any courses configured.</td>
            </tr>
          )}

          {courses.length > 0 &&
            courses.map((course) => (
              <tr key={course.name}>
                <td className="pl-0">
                  <a href="/instructor/courses/insights">{course.name}</a>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  }
}

export default CourseList;
