import CommitFeedback from './CommitFeedback';
import CommitInfo from './CommitInfo';
import React, { Component } from 'react';

/**
 * @brief Loads commit and feedback information for the selected course, 
 *        project and branch given the most recent commit.
 */
class Commit extends Component {
  render() {
    const {
      commitLoaded,
      commit,
      feedbackOptions,
      onSaveFeedbackConfiguration,
    } = this.props;

    return (
      <div
        className="card mb-3 mx-auto"
        style={{ width: '90%', border: '5px solid rgb(239, 241, 245)' }}
      >
        {commitLoaded && (
          <>
            <div className="card-header bg-white border-0">
              <h4 className="mb-3" style={{ fontWeight: 'bold' }}>Most Recent Commit</h4>
              {Object.keys(commit).length === 0 ? (
                'There is no commit to display for the selected branch and project'
              ) : (
                <CommitInfo commit={commit} />
              )}
            </div>
            {Object.keys(commit).length === 0 ? (
              ''
            ) : (
              <CommitFeedback
                feedbackOptions={feedbackOptions}
                onSaveFeedbackConfiguration={onSaveFeedbackConfiguration}
                commit={commit}
              />
            )}
          </>
        )}

        {!commitLoaded && (
          <div className="card-header bg-white border-0">
            <h4 style={{ fontWeight: 'bold' }}>Most Recent Commit</h4>
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Commit;
