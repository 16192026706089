import React, { Component } from 'react';
import RunnerPieChart from './RunnerPieChart';
import StudentPieChart from './StudentPieChart';
import styled from 'styled-components';
import SummaryBarChart from './SummaryBarChart';

const InsightsTitles = styled.h5`
  padding-top: 10px;
`

const InsightsGraphs = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
`

const GraphContainer = styled.div`
  padding-bottom: 50px;
`

const DefaultChartData = {
  labels: [],
  datasets: [{
    label: "",
    data:[]
  }]
}

/**
 * @brief Manipilates and displays the student overview data with graphs 
 */
class GraphicalInsights extends Component {
  state = {
    runnerPieChartData: DefaultChartData,
    runnerPieChartID: "",
    runnerPieChartTitle: ""
  }

  /**
   * Renders the graphcal insights.
   * @stateChange runnerPieChartData {bool}
   * @stateChange runnerPieChartID {string}
   * @stateChange runnerPieChartTitle {string}
   * @note Method gets invoked right after component has been rendered.
   */
  componentDidMount() {
    const { selectedStudentFeedback } = this.props;
    this.switchRunnerPieCharts(selectedStudentFeedback, 'data1');
  }

  /**
   * switchRunnerPieCharts handles the button switch between runner pie chart data being displayed
   * @param {*} selectedStudentFeedback the feedback data passed from coursePage.js retrieved from the server
   * @param {*} value the value of the object to determine which graph to switch between
   */
  switchRunnerPieCharts = (selectedStudentFeedback, value) => {
    const isData1 = value === "data1";

    const newData = isData1 
      ? this.tailorByRunnerData(selectedStudentFeedback.topFeedbackItems) 
      : this.tailorByFeedbackData(selectedStudentFeedback.topFeedbackItems);
    const newId = isData1 
      ? "pieChartByRunner" 
      : "pieChartByFeedbackData";
    const newTitle = isData1 
      ? "% of top feedback that comes from each runner" 
      : "% of all feedback received of each type";

    this.setState({
        runnerPieChartData: newData,
        runnerPieChartID: newId,
        runnerPieChartTitle: newTitle
    })
  }

  /**
   * tailorByRunnerData takes the feedbackData and formats what needs to be displayed in a way that Chart.js can understand
   * @param {*} feedbackData the feedback data retrieved from the server
   * @return data object for pie graph
   */
  tailorByRunnerData = (feedbackData) => {
    let data = {
      labels: [],
      datasets: [{
        label: "% of top feedback that comes from each runner",
        data:[]
      }]
    };

    let cumulative = [];

    for (let item of feedbackData) {
      let type = item['type'];
      if (data.labels.includes(type)) {
        cumulative[type] += item['numStudents'];
      } else {
        data.labels.push(type);
        cumulative[type] = item['numStudents'];
      }
    }

    for (let item of data.labels) {
      data.datasets[0].data.push(cumulative[item]);
    }

    return data;
  }

  /**
   * tailorByFeedbackData takes the feedbackData and formats what needs to be displayed in a way that Chart.js can understand
   * @param {*} feedbackData the feedback data retrieved from the server
   * @returns data object for pie graph
   */
  tailorByFeedbackData = (feedbackData) => {
    let data = {
      labels: [],
      datasets: [{
        label: "% of all feedback received of each type",
        data:[]
      }]
    };

    let cumulative = [];

    for (let item of feedbackData) {
      let type = item['name'];
      if (data.labels.includes(type)) {
        cumulative[type]+=item['numStudents'];
      } else {
        data.labels.push(type);
        cumulative[type] = item['numStudents'];
      }
    }

    for (let item of data.labels) {
      data.datasets[0].data.push(cumulative[item]);
    }

    return data;
  }

  /**
   * tailorPercentStudentsData takes the feedbackData and formats what needs to be displayed in a way that Chart.js can understand
   * @param {*} feedbackData the feedback data retrieved from the server
   * @param {*} studentData the student data retrieved from the server (total num students)
   * @returns data object for pie graph
   */
  tailorPercentStudentsData = (feedbackData, studentData) => {
    let data = {
      labels: ['students with feedback', 'students without feedback'],
      datasets: [{
        label: "% of all students enrolled with feedback of any kind",
        data:[feedbackData.overview.numStudents]
      }]
    };

    let difference = studentData.numStudents - feedbackData.overview.numStudents;
    data.datasets[0].data.push(difference);

    return data
  }

  render() {
    const { selectedStudentFeedback, studentSummaryData } = this.props;

    return (
      <div>
        <InsightsTitles>Graphical Insights:</InsightsTitles>
        <InsightsGraphs>
          <GraphContainer>
            <SummaryBarChart
              data={selectedStudentFeedback.topFeedbackItems}
              title="Most common feedback by occurance"
            />
          </GraphContainer>
          <GraphContainer>
            <InsightsGraphs>
              <div className="btn-group" role="group">
                <button
                  onClick={ () => this.switchRunnerPieCharts(selectedStudentFeedback, 'data1')}
                  type="button"
                  className="btn btn-secondary"
                >
                  Data by Runner
                </button>

                <button
                  onClick={() => this.switchRunnerPieCharts(selectedStudentFeedback, 'data2')}
                  type="button"
                  className="btn btn-secondary"
                >
                  Data by Feedback
                </button>
              </div>
              <RunnerPieChart
                data={this.state.runnerPieChartData}
                chartId={this.state.runnerPieChartID}
                title={this.state.runnerPieChartTitle}
                />
            </InsightsGraphs>
          </GraphContainer>
          <GraphContainer>
            <StudentPieChart
              data={this.tailorPercentStudentsData(selectedStudentFeedback, studentSummaryData)}
              chartId='pieChartByStudentData'
              title='% of all students enrolled with feedback of any kind'
            />
          </GraphContainer>
        </InsightsGraphs>
      </div>
    );
  }
}

export default GraphicalInsights;
