import axios from 'axios';
import Cookies from 'js-cookie';
import domain from '../../index.js'
import React, { Component } from 'react';
/**
 * @brief The login page
 */
class LoginPage extends Component {
  state = {};

  /**
   * Control where the login should redirect based on if a token exists.
   * @stateChange loggedIn {bool}
   * @note Method gets invoked right after the component has been mounted.
   */
  async componentDidMount() {
    if (Cookies.get('token')) {
      const isValidToken = await axios
        .get(`${domain.serverBase}/oauth/tokenIsValid?token=` + Cookies.get('token'))
        .then((response) => response.data);

      if (isValidToken) {
        const isInstructor = await axios
          .get(`${domain.serverBase}/user/isInstructor?token=` + Cookies.get('token'))
          .then((response) => response.data);

        isInstructor 
          ? this.props.history.push('/instructor/courses/') 
          : this.props.history.push('/student');
      } else {
        this.setState({ loggedIn: false });
      }
    } else {
      this.setState({ loggedIn: false });
    }
  }

  render() {
    if (this.state.loggedIn === false) {
      return (
        <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center">
          <div className="mx-auto my-5">
            <h1 className="display-4 font-weight-normal text-primary">
              Immediate Student Feedback System
            </h1>
            <p className="lead font-weight-normal">
              A system designed to give you immediate feedback on your course
              work.
              <br />
              Anytime, anyday.
            </p>
            <a
              className="btn btn-success mt-3"
              href={
                'https://gitlab.socs.uoguelph.ca/oauth/authorize?' +
                `client_id=${process.env.REACT_APP_CLIENTID}&` +
                `redirect_uri=${domain.serverBase}/oauth/getToken&` +
                'response_type=code&' +
                'scope=api'
              }
            >
              Log in using Gitlab Authentication
            </a>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default LoginPage;
