import React, { Component } from 'react';
import SelectMenu from './SelectMenu';

/**
 * @brief The selection (dropdown) menu for selecting courses that contains
 *        the projects, branches, students and feedback the instructor 
 *        wishes to view
 */
 class CourseSelect extends Component {
  render() {
    const { selectedCourse, courses, onCourseChange } = this.props;

    return (
      <SelectMenu
        selected={selectedCourse}
        data={courses}
        onChange={onCourseChange}
      />
    );
  }
}

export default CourseSelect;
