import React, { Component } from 'react';

/**
 * @brief A generic selector component (dropdown menu)
 */
class SelectMenu extends Component {
  render() {
    const { selected, data, onChange } = this.props;

    return (
      <select
        className="rounded border"
        onChange={onChange}
        value={selected}
        style={{ cursor: 'pointer', height: '40px' }}
      >
        {data.map((dataItem) => (
          <option key={dataItem.id} value={dataItem.id}>
            {dataItem.name}
          </option>
        ))}
      </select>
    );
  }
}

export default SelectMenu;
