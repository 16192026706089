import axios from 'axios';
import Cookies from 'js-cookie';
import domain from '../../index';
import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

/**
 * @brief  Controls the routing for the student and instructor pages. If a student tries to
 *         manually go to an instructor URL or an instructor tries to manually go to a student URL,
 *         they are redirected to root.
 */
class PrivateRoute extends Component {
  state = {};

  /**
   * Analyze who is logged in and set state accordingly.
   * @stateChange isValidToken {bool}
   * @stateChange isInstructor {bool}
   * @note Method gets invoked right after the component has been mounted.
   */
  async componentDidMount() {
    const isValidToken = await axios
      .get(`${domain.serverBase}/oauth/tokenIsValid?token=` + Cookies.get('token'))
      .then((response) => response.data);

    this.setState({ isValidToken });

    const isInstructor = await axios
      .get(`${domain.serverBase}/user/isInstructor?token=` + Cookies.get('token'))
      .then((response) => response.data);

    this.setState({ isInstructor });
  }

  render() {
    if (this.state.isInstructor === undefined) {
      return <div />;
    }

    if (this.state.isValidToken === false) {
      return <Redirect to="/" />;
    }

    if (
      (this.state.isInstructor === true &&
        this.props.path.startsWith('/instructor')) ||
      (this.state.isInstructor === false &&
        this.props.path.startsWith('/student'))
    ) {
      return (
        <Route
          path={this.props.path}
          component={this.props.component}
          exact={this.props.exact}
        />
      );
    }

    return <Redirect to="/" />;
  }
}

export default PrivateRoute;
