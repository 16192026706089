import BranchSelect from '../../student/components/studentPage/BranchSelect';
import CourseSelect from './CourseSelect';
import ProjectSelect from './ProjectSelect';
import React, { Component } from 'react';
import StudentSelect from '../../instructor/components/coursePage/StudentSelect';
import styled from 'styled-components';

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 80px;
  width: 90%;
`;

const Title = styled.h4`
  margin-left: 10px;
  margin-right: 10px;
`;

/**
 * @brief the course, project, branch and student selector menus. 
 */
class SelectionMenu extends Component {
  render() {
    const {
      coursesLoaded,
      selectedCourse,
      courses,
      onCourseChange,
      projectsLoaded,
      selectedProject,
      projects,
      onProjectChange,
      branchesLoaded,
      selectedBranch,
      branches,
      onBranchChange,
      studentsLoaded,
      selectedStudent,
      students,
      onStudentChange,
      isInstructor,
    } = this.props;

    let margin = isInstructor ? 'mx-auto' : '';

    return (

      <MenuContainer className={margin}>
        {coursesLoaded && (
          <>
            <Title className="ml-0">Course:</Title>
            <CourseSelect
              selectedCourse={selectedCourse}
              courses={courses}
              onCourseChange={onCourseChange}
            />
          </>
        )}

        {projectsLoaded && (
          <>
            <Title>Project:</Title>
            <ProjectSelect
              selectedProject={selectedProject}
              projects={projects}
              onProjectChange={onProjectChange}
            />
          </>
        )}

        {branchesLoaded && (
          <>
            <Title>Branch:</Title>
            <BranchSelect
              selectedBranch={selectedBranch}
              branches={branches}
              onBranchChange={onBranchChange}
            />
          </>
        )}

        {isInstructor && studentsLoaded && (
          <>
            <Title>Student:</Title>
            <StudentSelect
              selectedStudent={selectedStudent}
              students={students}
              onStudentChange={onStudentChange}
            />
          </>
        )}
      </MenuContainer>
    );
  }
}

export default SelectionMenu;
