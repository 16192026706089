import React, { Component } from 'react';
import StudentFeedback from './StudentFeedback';

/**
 * @brief Processes and displays summary data for instructor's selected project options
 */
class Insights extends Component {
  state = {
    date: '',
  };

  /**
   * Retrieves the current date and formats it for display.
   * @stateChange date {string}
   * @note Method gets invoked right after component has been rendered.
   */
     componentDidMount() {
      this.constructDate();
    }

  /**
   * Retrieves the current date and formats it for display.
   * @stateChange date {string}
   */
  constructDate = () => {
    var d = new Date();
    var day = d.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    var m = d.getMonth() + 1;
    var month = ((m < 10) ? `0${m}` : m)
    var year = d.getFullYear();
    this.setState ({date: `${day}-${month}-${year}`});
  }

  render() {
    const {
      feedbackLoaded,
      selectedStudentFeedback,
      studentSummaryData
    } = this.props;

    return (
      <div
        className="card mb-3 mx-auto"
        style={{ width: '90%', border: '5px solid rgb(239, 241, 245)' }}
      >
        {feedbackLoaded && (
          <>
            <div className="card-header bg-white border-0">
              <h4 className="mb-3" style={{ fontWeight: 'bold' }}>
                {this.state.date}
              </h4>
            </div>
            {Object.keys(selectedStudentFeedback).length === 0 ? (
              'there is no data to display'
            ) : (
              <StudentFeedback
                selectedStudentFeedback={selectedStudentFeedback}
                studentSummaryData={studentSummaryData}
                date={this.state.date}
              />
            )}
          </>
        )}

        {!feedbackLoaded && (
          <div className="card-header bg-white border-0">
            <h4 style={{ fontWeight: 'bold' }}>{this.state.date}</h4>
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Insights;
