import React, { Component } from 'react';

/**
 * @brief The preview for the group to be configured.
 */
class UnconfiguredGroupPreview extends Component {
  render() {
    const {
      continueFromPreview,
      previewGroup,
      onContinueFromPreviewClick,
      onEditGroupIdClick,
    } = this.props;

    return (
      <div className="d-inline-flex flex-column border bg-white rounded p-2">
        {/*If the previewGroup is an empty object, display the following message*/}
        {Object.keys(previewGroup).length === 0 && (
          <div
            className="mb-2"
            style={{ color: 'red' }}
            data-testid="emptyPreviewGroupOutput"
          >
            <b>
              <div>The course corresponding to the Gitlab Group ID you provided can not be configured.</div>
              <div>This course may have already been configured, or you do not have access to the course on gitlab.</div>
              <div>Please ensure you have entered the correct Gitlab Group ID.</div>
            </b>
          </div>
        )}

        {/*If the previewGroup is not an empty object, display the following*/}
        {Object.keys(previewGroup).length > 0 && (
          <div data-testid="preview">
            {/*Show Preview title if the user has not continued from the preview*/}
            {continueFromPreview === false && (
              <div data-testid="previewTitle">
                <h5>Preview</h5>
              </div>
            )}

            {/*Unconfigured course name*/}
            <div className="mb-2" data-testid="previewName">
              <b>Course (Gitlab Group) Name: </b>
              {previewGroup.name}
            </div>

            {/*Unconfigured course URL - to group on gitlab (automatically opens in a new tab)*/}
            <div
              className="d-inline-flex flex-row mb-2"
              data-testid="previewURL"
            >
              <b className="mr-1">Course (Gitlab Group) URL: </b>
              <a
                href={previewGroup.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {previewGroup.url}
              </a>
            </div>
          </div>
        )}

        <div>
          {/**Show Continue button if Gitlab Group ID is valid 
             and the user has not chosen to Continue yet*/}
          {continueFromPreview === false &&
            Object.keys(previewGroup).length > 0 && (
              <button
                className="btn btn-success mr-2"
                onClick={onContinueFromPreviewClick}
                data-testid="continueButton"
              >
                Continue
              </button>
            )}

          {/*Always show the button for editing the gitlab group ID*/}
          <button
            className="btn btn-secondary"
            onClick={onEditGroupIdClick}
            data-testid="editGitlabGroupIdButton"
          >
            Edit Gitlab Group ID
          </button>
        </div>
      </div>
    );
  }
}

export default UnconfiguredGroupPreview;
