import FeedbackOption from '../../../common/components/FeedbackOption';
import React, { Component } from 'react';
import styled from 'styled-components';

const FormContainer = styled.div`
  border-radius: 5px;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 10px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
`;

const Title = styled.div`
  font-size: 20px;
`;

/**
 * @brief Form which allows student to choose which feedback 
 *        types you wish to view for already configured projects.
 */
class FeedbackConfigForm extends Component {
  state = {
    feedbackOptions: this.props.feedbackOptions,
  };

  /**
   * Updates the feedbackOptions given the forms new input.
   * @param prevProps
   * @stateChange feedbackOptions { {name: string, id: number, selected: boolean}[] }
   * @note Method gets invoked right after component has been updated.
   */
  componentDidUpdate(prevProps) {
    if (this.props.feedbackOptions !== prevProps.feedbackOptions) {
      this.setState({ feedbackOptions: this.props.feedbackOptions });
    }
  }

  /**
   * Updates select state of the option in the feedbackOptions.
   * @param option {name: string, id: number, selected: boolean}
   * @stateChange feedbackOptions { {name: string, id: number, selected: boolean}[] }
   */
  handleOptionSelectionChange = (option) => {
    const { onSaveFeedbackConfiguration } = this.props;
    let feedbackOpts = this.state.feedbackOptions;

    for (const feedbackOpt of feedbackOpts) {
      if (feedbackOpt.id === option.id) {
        feedbackOpt.selected = option.selected;
      }
    }

    this.setState(
      (state) => ({
        feedbackOptions: feedbackOpts,
      }),
      () => {
        onSaveFeedbackConfiguration(this.state.feedbackOptions);
      }
    );
  };

  render() {
    const feedbackOptions = this.state.feedbackOptions;

    return (
      <FormContainer className="border">
        <Title className="mr-1">Filter Feedback:</Title>
        {feedbackOptions.map((option) => (
          <FeedbackOption
            key={option.id}
            option={option}
            onOptionChange={this.handleOptionSelectionChange}
          />
        ))}
      </FormContainer>
    );
  }
}

export default FeedbackConfigForm;
