import axios from 'axios';
import Commit from '../components/studentPage/Commit';
import Cookies from 'js-cookie';
import domain from '../../index';
import React, { Component } from 'react';
import SelectionMenu from '../../common/components/SelectionMenu';
import styled from 'styled-components';
import TopMenuBar from '../../common/components/TopMenuBar';

const AppContainer = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  color: #222222;
`;

/**
 * @brief This page is the student view of the application. This page will load
 *        dropdown menus containing Courses, Projects and Branches. Likwise, it
 *        will also load the feedback for the selected project.
 */
class StudentPage extends Component {
  state = {
    courses: [],
    selectedCourse: '',
    projects: [],
    selectedProject: '',
    branches: [],
    selectedBranch: '',
    feedbackOptions: [],
    mostRecentCommit: {},
    coursesLoaded: false,
    projectsLoaded: false,
    branchesLoaded: false,
    commitLoaded: false,
    personalExists: false,
  };

  /**
   * Checks to see if student database contains any configured personal projects.
   * @stateChange personalExists {bool} 
   */
  isPersonalConfigured = async () => {
    try {
      let response = await axios.get(`${domain.serverBase}/student/projects/personal/unconfigured?token=` +
                                     Cookies.get('token'));

      if(response.data.length > 0) {
        this.setState({ personalExists: true });
      } else {
        this.setState({ personalExists: false });
      }

    } catch (err) {
      this.setState({ personalExists: false })
    } 
  }

 /**
  * updates the state of the page with the up to date personal projects
  * when the "Personal" course is selected. 
  * @stateChange projects {id: string, name: string}[]
  * @stateChange selectedProject {string}
  * @stateChange projectsLoaded {bool}
  * @stateChange couresLoaded {bool} 
  */
 getPersonalProjectsFromServer = async () => {
   try {
    let response = await axios.get(`${domain.serverBase}/student/projects/personal/configured?token=` +
                                   Cookies.get('token'));

    this.setState({ projects: response.data });
    this.setState({ selectedProject: this.state.projects[0].id });

    } catch (err) {
        this.setState({ projects: err.response.data });
    } finally {
        this.setState({ projectsLoaded: true });
    }
  };

  /**
   * Gets all configured courses from student's gitlab account 
   * and updates page state accordingly. 
   * @stateChange courses {id: string, parentId: string, name: string}[]
   * @stateChange selectedCourse {string}
   * @stateChange coursesLoaded {bool}
   * @note If Personal projects exists, manually add a "personal" course
   *       to the list of courses with id and parentid of 0. This is required
   *       in order to get personal projects from student database. We need to
   *       think of a better way to load personal projects
   */
  getCoursesFromServer = async () => {
    try {
      let response = await axios.get(`${domain.serverBase}/student/groups/configured?token=` +
                                     Cookies.get('token'));

      if(this.state.personalExists) {
        response.data.push({id: '0', parentId: '0', name: 'Personal'});
      }

      this.setState({ courses: response.data });

      if(this.state.courses.length > 0) {
        this.setState({ selectedCourse: this.state.courses[0].id });
      }
    } catch (err) {
      this.setState({ courses: err.response.data });
    } finally {
      this.setState({ coursesLoaded: true });
    }
  };

  /**
   * Gets all the configured projects from the student's gitlab account 
   * for a selected course and updates the page state accordingly.
   * @stateChange projects {id: string,  name: string}[]
   * @stateChange selectedProject {string}
   * @stateChange projectsLoaded {bool}
   */
  getProjectsFromServer = async () => {
    try {
      let response = await axios.get(`${domain.serverBase}/student/groups/configured/${this.state.selectedCourse}/projects?token=` +
                                     Cookies.get('token'));

      this.setState({ projects: response.data });
      this.setState({ selectedProject: this.state.projects[0].id });
    } catch(err) {
      this.setState({ projects: err.response.data });
    } finally {
      this.setState({ projectsLoaded: true });
    }
  };

  /**
   * Gets the configured branches from the student's gitlab account for a 
   * selected course and project and updates the state of the page sccordingly.
   * @stateChange branches {name: string}[]
   * @stateChange selectedBranch {string}
   * @stateChange branchesLoaded {bool}
   */
  getBranchesFromServer = async () => {
    try {
      let response = await axios.get(`${domain.serverBase}/student/projects/configured/${this.state.selectedProject}/branches?token=` +
                               Cookies.get('token'));

      this.setState({ branches: response.data });
      this.setState({ selectedBranch: this.state.branches[0].name }); 
    } catch (err) {
      this.setState({ branches: err.response.data });
    } finally {
      this.setState({ branchesLoaded: true });
    }
  };

  /**
   * Adds a ".gitlab-ci.yml" to selected project and branch in the student's gitlab account. 
   * The yml file runs the CI pipeline tests from which we fetch the feedback from.
   * @note Given that personal projects and instructor configured project use two different
   *       databases, we need to use two different routes in order to grab projects (one route to 
   *       get projects from instructor configured course database, one route to get personal 
   *       projects from student database) We definitely need to find a better soloution.
   */
  setContinuousIntegrationForBranch = async () => {
    if(this.state.selectedCourse !== '0') {
      await axios.post(
        `${domain.serverBase}/student/projects/configured/${this.state.selectedProject}/branches/${this.state.selectedBranch}/ci?token=` +
        Cookies.get('token')
      );
    } else {
      await axios.post(
        `${domain.serverBase}/student/projects/personal/configured/${this.state.selectedProject}/branches/${this.state.selectedBranch}/ci?token=` +
        Cookies.get('token')
      );
    }
  };

  /**
   * Gets the feedback options configured for the selected course, project and branch and updates
   * the state of the page accordingly.
   * @stateChange feedbackOptions {name: string, id: number, selected: bool}[] 
   * @note Given that personal projects and instructor configured project use two different
   *       databases, it wa necesary to have two different routes as one analyzez the istructor
   *       database and one analyzes the student database. We definitely need to find a better 
   *       soloution.
   */
  getFeedbackOptionsFromServer = async () => {
    if(this.state.selectedCourse !== '0') {
      let response = await axios.get(`${domain.serverBase}/student/projects/configured/${this.state.selectedProject}/feedback-options?token=` +
                                     Cookies.get('token'));
      
      this.setState({ feedbackOptions: response.data});
    } else {
      let response = await axios.get(`${domain.serverBase}/student/projects/personal/configured/${this.state.selectedProject}/feedback-options?token=` +
                                     Cookies.get('token'));
      
      this.setState({ feedbackOptions: response.data});
    }
  };

  /**
   * Gets the most recent commit information and feedback options for the selected course, project and branch 
   * and updates the state of the page accordingly.
   * @stateChange mostRecentCommit { date: string, 
   *                                 time: string, 
   *                                 URL: string, 
   *                                 feedback: {title: string, feedbackItems: [], overview: []}[] 
   *                                 pipelineStatus: string 
   *                               }
   * @stateChange commitLoaded {bool}
   */
  getMostRecentCommitFromServer = async () => {
    try {
      let response = await axios.get(`${domain.serverBase}/student/projects/configured/${this.state.selectedProject}/branches/${this.state.selectedBranch}/last-commit?token=` +
                                     Cookies.get('token'))

      this.setState({ mostRecentCommit: response.data });
    } catch (err) {
      this.setState({ mostRecentCommit: err.response.data });
    } finally {
      this.setState({ commitLoaded: true });
    }
  };

  /**
   * Initializes the projects, branches and feedback for an instructor configured (non-personal) course
   */
  loadNonPersonalCourse() {
    this.getProjectsFromServer().then(() => {
      this.getBranchesFromServer().then(() => {
        this.setContinuousIntegrationForBranch().then(() => {
          this.getFeedbackOptionsFromServer().then(() => {
            this.getMostRecentCommitFromServer();
          });
        });
      });
    });
  }

  /**
   * Initializes the projects, branches and feedback for a non-instructor configured (personal) course
   */
  loadPersonalCourse() {
    this.getPersonalProjectsFromServer().then(() => {
      this.getBranchesFromServer().then(() => {
        this.setContinuousIntegrationForBranch().then(() => {
          this.getFeedbackOptionsFromServer().then(() => {
            this.getMostRecentCommitFromServer();
          });
        });
      });
    });
  }

  /**
   * Initializes the page with relevant courses, projects, branches and feedback data.
   * @note Method gets invoked right after the component has been mounted.
   */
  componentDidMount() {
    this.isPersonalConfigured().then(() => {
      this.getCoursesFromServer().then(() => {
        if (this.state.courses.length > 0) {
          if(this.state.selectedCourse !== '0') {
              this.loadNonPersonalCourse();
            } else {
              this.loadPersonalCourse();
          }
        }
      });
    });
  }

  /**
   * Gets the project, branch and feedback data for the newly selected course and updates the state
   * of the page accordingly.
   * @param event  
   * @stateChange selectedCourse {string}
   * @stateChange projectsLoaded {bool}
   * @stateChange branchesLoaded {bool}
   * @stateChange commitLoaded {bool}
   */
  handleCourseChange = (event) => {
    const selectedCourse = event.target.value;

    this.setState(
      {
        selectedCourse,
        projectsLoaded: false,
        branchesLoaded: false,
        commitLoaded: false,
      },
      () => {
        if(this.state.selectedCourse !== '0') {
          this.loadNonPersonalCourse();
        } else {
          this.loadPersonalCourse();
        }
      }
    );
  };

  /**
   * Gets the branch and feedback data for the newly selected project and updates the state
   * of the page accordingly. Courses should remain unchange.
   * @param event  
   * @stateChange selectedProject {string}
   * @stateChange branchesLoaded {bool}
   * @stateChange commitLoaded {bool}
   */
  handleProjectChange = (event) => {
    const selectedProject = event.target.value;

    this.setState(
      { selectedProject, 
        branchesLoaded: false, 
        commitLoaded: false },
      () => {
        this.getBranchesFromServer().then(() => {
          this.setContinuousIntegrationForBranch().then(() => {
            this.getFeedbackOptionsFromServer();
            this.getMostRecentCommitFromServer();
          });
        });
      }
    );
  };

  /**
   * Gets the feedback data for the newly selected branch and updates the state
   * of the page accordingly. Courses and Projects should remain unchanged.
   * @param event  
   * @stateChange selectedBranch {string}
   * @stateChange commitLoaded {bool}
   */
  handleBranchChange = (event) => {
    const selectedBranch = event.target.value;

    this.setState({ selectedBranch, commitLoaded: false }, () => {
      this.setContinuousIntegrationForBranch().then(() => {
        this.getFeedbackOptionsFromServer();
        this.getMostRecentCommitFromServer();
      });
    });
  };

  /**
   * Gets the feedback for the selected feedback options for the selected course, project and branch.
   * Updates the state of the page accordingly. Courses, Projects and Branches should remain unchanged.
   * Only selected feedbackOptions will be loaded.
   * @param  feedbackOptions { {name: string, id: number, selected: bool}[] }
   * @stateChange feedbackOptions { {name: string, id: number, selected: bool}[] }
   */
  handleFeedbackConfigChange = async (feedbackOptions) => {
    this.setState({
      feedbackOptions,
    });
  };

  /**
   * When logout button is clicked, return to the login page.
   * @note revoking the cookie doensn't seem to work as we want it to...
   */
  handleLogoutButton = () => {
    Cookies.remove('token');
    this.props.history.push('/');
  };

  /**
   * When the button to add a new project is clicked, go to the page which 
   * will handle the process to add a new personal projects to IFS.
   */
  handleNewProjectNav = () => {
    this.props.history.push('/student/new-project');
  };

  render() {
    if (this.state.coursesLoaded === false) {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h1 className="text-primary">Immediate Student Feedback</h1>
          <h2>Loading...</h2>
          <div className="spinner-border text-primary" />
        </div>
      );
    } else {
      return (
        <>
          <header style={{ backgroundColor: 'rgb(173, 205, 255)' }}>
            <TopMenuBar 
              onLogoutClick={this.handleLogoutButton} 
              isInstructor={false}/>
          </header>
          <AppContainer>
            {/*If there are any courses configured for the student, output the selection menu and commit with feedback*/}
            {this.state.courses.length > 0 && (
              <>
              <div className="d-flex flex-row align-items-center justify-content-between" style={{ width: '90%', margin:'auto'}}>
                <SelectionMenu
                  coursesLoaded={this.state.coursesLoaded}
                  projectsLoaded={this.state.projectsLoaded}
                  branchesLoaded={this.state.branchesLoaded}
                  selectedCourse={this.state.selectedCourse}
                  courses={this.state.courses}
                  onCourseChange={this.handleCourseChange}
                  selectedProject={this.state.selectedProject}
                  projects={this.state.projects}
                  onProjectChange={this.handleProjectChange}
                  selectedBranch={this.state.selectedBranch}
                  branches={this.state.branches}
                  onBranchChange={this.handleBranchChange}
                  isInstructor={false}
                />
                <button className="btn btn-primary" onClick={this.handleNewProjectNav}>
                  Add Project   
                </button>
              </div>
              <Commit
                commitLoaded={this.state.commitLoaded}
                commit={this.state.mostRecentCommit}
                feedbackOptions={this.state.feedbackOptions}
                onSaveFeedbackConfiguration={this.handleFeedbackConfigChange}
              />
            </>
            )}

            {/*If there are no courses configured for the student, output this message*/}
            {this.state.courses.length === 0 && (
              <>
                <div
                  className="d-flex flex-row justify-content-center align-items-center mt-2"
                  style={{ fontSize: '18px' }}
                  data-testid="noCoursesMessage"
                >
                  It looks like none of your courses are set up to provide student
                  feedback.
                </div>
                <div className="d-flex flex-row justify-content-center align-items-center mt-2">
                  <button className="btn btn-primary" onClick={this.handleNewProjectNav}>
                    Add Project   
                  </button>
                </div>
              </> 
            )}
          </AppContainer>
        </>
      );
    }
  }
}

export default StudentPage;
