import React, { Component } from 'react';
import SelectMenu from '../../../common/components/SelectMenu';

/**
 * @brief The selection (dropdown) menu for selecting students that 
 *        instructor wishes to view feedback for
 */
class StudentSelect extends Component {
  render() {
    const { selectedStudent, students, onStudentChange } = this.props;

    return (
      <SelectMenu
        selected={selectedStudent}
        data={students}
        onChange={onStudentChange}
      />
    );
  }
}

export default StudentSelect;
