import GraphicalInsights from './GraphicalInsights';
import FeedbackItem from '../../../common/components/FeedbackItem';
import FileSaver from 'file-saver';
import React, { Component } from 'react';
import styled from 'styled-components';
import XLSX from 'xlsx';

const FormContainer = styled.div`
  border-radius: 5px;
  padding: 15px;
  background-color: white;
`;

/**
 * @brief Displays each piece of feedback for the class feedback overview
 *        data, including graphical representations.
 */
class StudentFeedback extends Component {

  /**
   * Triggered when the download button is pressed, passees feedback data into an xlsx file.
   * @param {*} selectedStudentFeedback the feedback object retrieved from the server from CoursePage.js
   * @param {*} date the data value passed from Insights.js to label the file properly
   * @note 'eslint-disable-next-line no-unused-vars' comment is necessary to prevent an innevitable warning
   */
  handleCSVDownload = (selectedStudentFeedback, date) => {
    var workbook = XLSX.utils.book_new();

    for (let sheet in selectedStudentFeedback) {
      let data = [];
      var isArray = Array.isArray(selectedStudentFeedback[sheet]);
      var headers = isArray ? Object.keys(selectedStudentFeedback[sheet][0]) : Object.keys(selectedStudentFeedback[sheet]);
      data.push(headers);

      if (isArray) {
        selectedStudentFeedback[sheet].forEach(element => {
          var item = [];
          for (let val in element) {
            item.push(element[val]);
          }
          data.push(item);
        });
      } else {
        var item = [];

        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(selectedStudentFeedback[sheet])) {
          item.push(value);
        }
        data.push(item);
      }

      var worksheet = XLSX.utils.aoa_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheet);
    }

    var wb = XLSX.write(workbook, {bookType:'xlsx',  type: 'binary'})
    this.downloadCSV(wb, date);
  }

  /**
   * s2ab encodes the workbook so that it can be downloaded appropriately
   * @note needs a better name
   */
  s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  /**
   * downloadCSV downloads the file onto the user's system
   * @param {*} wb workbook data created in handleCSVDownload
   * @param {*} date the data value passed from Insights.js to label the file properly
   */
  downloadCSV = (wb, date) => {
    FileSaver.saveAs(new Blob([this.s2ab(wb)],{type:"application/octet-stream"}), `class_feedback_${date}.xlsx`);
  }

  render() {
    const { selectedStudentFeedback, studentSummaryData, date } = this.props;

    selectedStudentFeedback.topFeedbackItems.forEach((feedback) => {
      return feedback['percentStudents'] = Math.round((feedback['percentStudents'] + Number.EPSILON) * 100) / 100;
    });

    return (
      <div
        className="card-body pt-3 pb-0"
        style={{ backgroundColor: 'rgb(239, 241, 245)' }}
      >
        <h4 className="mb-3" style={{ fontWeight: 'bold' }}>
          Class Feedback
        </h4>

        <div>
          <button className="btn btn-success" onClick={this.handleCSVDownload.bind(null, selectedStudentFeedback, date)}>
            Download CSV
          </button>
          <div>
            <FormContainer className="border my-3">
              <h5>Top Feedback Items:</h5>
              {selectedStudentFeedback.topFeedbackItems.map((feedback, i) => (
                <FeedbackItem
                  feedbackItem={feedback}
                  key={i}
                  count={i+1}
                />
              ))}
              <GraphicalInsights
                selectedStudentFeedback={selectedStudentFeedback}
                studentSummaryData={studentSummaryData}
              />
            </FormContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default StudentFeedback;
