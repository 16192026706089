import AllCoursesPage from './instructor/pages/AllCoursesPage';
import CoursePage from './instructor/pages/CoursePage';
import LoginPage from './top-level/pages/LoginPage';
import NewCoursePage from './instructor/pages/NewCoursePage';
import NewProjectPage from './student/pages/NewProjectPage';
import PrivateRoute from './top-level/components/PrivateRoute';
import React, { Component } from 'react';
import RedirectPage from './top-level/pages/RedirectPage';
import { Route, Switch, Redirect } from 'react-router-dom';
import StudentPage from './student/pages/StudentPage';

/**
 * @brief The app compoenent. Sorts out the apps routes.
 */
class App extends Component {
  render() {
    return (
      <div>
        <Switch>
          <PrivateRoute path="/instructor/courses/new-course" component={NewCoursePage}/>
          <PrivateRoute path="/student/new-project" component={NewProjectPage}/>
          <PrivateRoute path="/instructor/courses/insights" component={CoursePage} />
          <PrivateRoute path="/instructor/courses" component={AllCoursesPage} />
          <PrivateRoute path="/student" component={StudentPage} />
          <Route path="/redirect" component={RedirectPage} />
          <Route path="/" component={LoginPage} exact />
          <Redirect to="/" />
        </Switch>
      </div>
    );
  }
}

export default App;
