import axios from 'axios';
import Cookies from 'js-cookie';
import CourseConfigConfirmationModal from '../components/newCoursePage/CourseConfigConfirmationModal';
import domain from '../../index';
import ProjectConfig from '../components/newCoursePage/ProjectConfig';
import React, { Component } from 'react';
import TopMenuBar from '../../common/components/TopMenuBar';
import UnconfiguredGroupIDForm from '../../common/components/UnconfiguredGroupIDForm';
import UnconfiguredGroupPreview from '../components/newCoursePage/UnconfiguredGroupPreview';

/**
 * @brief Page used to allow instructor to 
 *        configure a new course to IFS.
 */
class NewCoursePage extends Component {
  state = {
    previewId: '',
    showPreview: false,
    continueFromPreview: false,
    previewGroup: {},
    projects: [],
    selectedProjects: [],
    cannotBeSaved: false,
    saving: false,
    errorPosting: false,
  };

  /**
   * Retrieves the preview of the unconfigured group from the server
   * and changes the state of the page accordingly
   * @param previewId {string}
   * @stateChange preciewId {string}
   * @stateChange showPreview {bool}
   */
  showGitlabPreview = (previewId) => {
    this.setState(
      (state) => ({
        previewId,
      }),
      async () => {
        try {
          let response = await axios.get(`${domain.serverBase}/instructor/groups/unconfigured/${this.state.previewId}?token=` +
                                        Cookies.get('token'));

          this.setState({ previewGroup: response.data });
        } catch (err) {
          this.setState({ previewGroup: err.response.data });
        } finally {
          this.setState({ showPreview: true });
        }
      }
    );
  };

  /**
   * Retrieves the projects for the unconfigured group from the server
   * Changes the state of continueFromPreview to true
   * @param {event} event
   * @stateChange projects {name: string, configuring: bool}[] 
   * @stateChange continueFromPreview {bool}
   */
  continueFromPreview = async (event) => {
    event.preventDefault();

    try {
      let response = await axios.get(`${domain.serverBase}/instructor/groups/unconfigured/${this.state.previewId}/projects?token=` +
                                     Cookies.get('token'));

      let projects = response.data;
      for (const project of projects) {
        project.configuring = false;
      }

      this.setState({projects});
    } catch (err) {
      this.setState({ projects: err.response.data });
    } finally {
      this.setState({ continueFromPreview: true });
    }
  };

  /**
   * If the instructor wants to edit the group ID, change the state of 
   * page to allow them to do it.
   * @stateChange showPeview {bool}
   * @stateChange continueFromPreview {bool}
   * @note may need to change cannotBeSaved: false
   */
  editGroupId = () => {
    this.setState({ showPreview: false, continueFromPreview: false });
  };

  /**
   * Changes whether a project is being configured or not within the projects held in the state
   * @param {String} projectName
   * @param {Boolean} configState
   * @stateChange project {name: string, configuring: bool}  
   */
  handleProjectConfigStateChange = (projectName, configState) => {
    let currProjects = this.state.projects;

    for (const project of currProjects) {
      if (projectName === project.name) {
        project.configuring = configState;
        break;
      }
    }

    this.setState({ projects: currProjects });
  };

  /**
   * Changes which feedback options are selected for a project being configured
   * and changes the state of page accordingly
   * @param {String} projectName
   * @param { {name: string, id: number, selected: bool}[] } feedbackOptions
   * @stateChange project {name: string, 
   *                       configuring: bool, 
   *                       chosenFeedback: {name: string, id: number, selected: bool}[] 
   *                      }  
   */
  handleProjectFeedbackOptionSelectionChange = (projectName, feedbackOptions) => {
    let currProjects = this.state.projects;

    for (const project of currProjects) {
      if (projectName === project.name) {
        project.chosenFeedback = feedbackOptions;
        break;
      }
    }

    this.setState({ projects: currProjects });
  };

  /**
   * Handle if an instructor chooses to configure the course and checks
   * if the configuration is valid
   * @stateChange cannotBeSaved {bool}
   * @stateChange saving {bool}
   */
  handleAddingCourseConfiguration = () => {
    let projects = this.state.projects;

    let configuringCount = 0;
    let missingChosenFeedback = false;

    for (const project of projects) {
      if (project.configuring === true) {
        configuringCount++;

        if (!project.chosenFeedback || project.chosenFeedback.length === 0) {
          missingChosenFeedback = true;
        }
      }
    }

    if (configuringCount === 0 || missingChosenFeedback) {
      this.setState({ cannotBeSaved: true });
    } else {
      this.setState({ cannotBeSaved: false });
      this.setState({ saving: true });
    }
  };

  /**
   * Cancels configuraton by changin page
   * @param event
   * @stateChange saving {bool}
   */
  handleCancellingConfig = (event) => {
    event.preventDefault();
    this.setState({ saving: false });
  };

  /**
   * Adds the coure to instructors IFS account and redirects to 
   * AllCoursesPage where course should now be viewable if no error occured.
   * @param {event} event
   * @stateChane errorposting {bool}
   */
  handleSubmitConfig = async (event) => {
    event.preventDefault();

    let projects = this.state.projects;
    let configuredProjects = [];

    for (const project of projects) {
      if (project.configuring === true) {
        let selectedFeedback = [];

        for (const feedback of project.chosenFeedback) {
          selectedFeedback.push({ name: feedback.name });
        }

        let projectToAdd = { name: project.name, feedbackTypes: selectedFeedback };
        configuredProjects.push(projectToAdd);
      }
    }

    let configuration = {
      groupId: parseInt(this.state.previewId),
      groupName: this.state.previewGroup.name,
      projects: configuredProjects,
    };

    try {
      await axios.post(`${domain.serverBase}/instructor/groups/configure?token=` + 
                        Cookies.get('token'),
                        configuration
      );

      this.props.history.push('/instructor/courses');
    } catch (err) {
      this.setState({ errorPosting: true });
    }
  };

  /**
   * When logout button is clicked, return to the login page.
   * @note revoking the cookie doensn't seem to work as we want it to...
   */
  handleLogoutButton = () => {
    Cookies.remove('token');
    this.props.history.push('/');
  };

  render() {
    return (
      <>
        <TopMenuBar 
          onLogoutClick={this.handleLogoutButton} 
          isInstructor={true}/>
        <div className="mt-3 mx-auto" style={{ width: '80%' }}>
          <div
            className="d-inline-flex border rounded-top border-bottom-0 pt-3 pl-3 pr-3 pb-2"
            style={{ backgroundColor: 'rgb(239, 241, 245)' }}
          >
            <h4>New Course</h4>
          </div>

          <div
            className="d-flex flex-column border pt-3 pl-3 pr-3 pb-2"
            style={{
              borderRadius: '0px 5px 5px 5px',
              backgroundColor: 'rgb(239, 241, 245)',
            }}
          >
            {/*Form to enter a gitlab group id for configuration */}
            <UnconfiguredGroupIDForm
              showPreviewState={this.state.showPreview}
              showPreview={this.showGitlabPreview}
              groupOrProjectLabel="Group"
            />

            {/*Preview of gitlab group based on entered gitlab group id */}
            {this.state.showPreview && (
              <UnconfiguredGroupPreview
                continueFromPreview={this.state.continueFromPreview}
                previewGroup={this.state.previewGroup}
                onContinueFromPreviewClick={this.continueFromPreview}
                onEditGroupIdClick={this.editGroupId}
              />
            )}

            {/**If instructor continues from the preview and there are any projects that exist in 
                  all subgroups of the group to be configured, show the following */}
            {this.state.continueFromPreview && this.state.projects.length > 0 && (
              <div className="d-inline-flex flex-column pt-2">
                {/*Show a configuration form for each project to be configured*/}
                {this.state.projects.map((project) => (
                  <ProjectConfig
                    key={project.name}
                    project={project}
                    onConfiguredStateChange={this.handleProjectConfigStateChange}
                    onFeedbackOptionSelectionChange={
                      this.handleProjectFeedbackOptionSelectionChange
                    }
                  />
                ))}

                {/*Show button to configure a course*/}
                <div>
                  <button
                    className="btn btn-success"
                    onClick={this.handleAddingCourseConfiguration}
                  >
                    Configure Course
                  </button>
                </div>

                {/*Show message to user if the configuration is invalid and cannot be saved*/}
                {this.state.cannotBeSaved && (
                  <div className="mt-2" style={{ color: 'red' }}>
                    <b>
                      The current configuration cannot be saved.
                      <div>
                        To save the configuration, at least one repository must
                        be configured and each repository you choose to
                        configure must have at least one feedback type selected.
                      </div>
                    </b>
                  </div>
                )}

                {/*Show the confirmation modal if the configuration can be submitted*/}
                {this.state.saving && (
                  <CourseConfigConfirmationModal
                    onCancelConfig={this.handleCancellingConfig}
                    onSubmitConfig={this.handleSubmitConfig}
                  />
                )}

                {/*Show message to user if the configuration fails to be posted */}
                {this.state.errorPosting && (
                  <div className="mt-2" style={{ color: 'red' }}>
                    <b>
                      The current configuration could not be saved.
                      <div>
                        Please try again or contact an administrator if the
                        error continues.
                      </div>
                    </b>
                  </div>
                )}
              </div>
            )}

            {/**If instructor continues from the preview and there are no projects that exist in 
                  all subgroups of the group to be configured, show the following message*/}
            {this.state.continueFromPreview && this.state.projects.length === 0 && (
              <div
                className="d-inline-flex flex-column pt-2"
                style={{ color: 'red' }}
              >
                <b>
                  <div>This course cannot be configured at this time.</div>
                  <div>
                    To configure a course, each student must have access to at
                    least one repository with a common name.
                  </div>
                  <div>
                    For instance, all students could have access to their own
                    repository named 'project1'.
                  </div>
                  <div>
                    To configure this course to provide feedback to students,
                    please ensure your course is configured correctly on gitlab.
                  </div>
                </b>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default NewCoursePage;
