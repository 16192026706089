import React, { Component } from 'react';

/**
 * @brief All feedback option that can be selected when 
 *        student configures a personal project to add to IFS.
 */
class FeedbackOption extends Component {
  state = { option: this.props.option };

  /**
   * Updates the feedbackOptions given the forms new input.
   * @param prevProps
   * @stateChange option { {name: string, id: number, selected: boolean} }
   * @note Method gets invoked right after component has been updated.
   */
  componentDidUpdate(prevProps) {
    if (this.props.option !== prevProps.option) {
      this.setState({ option: this.props.option });
    }
  }

  /**
   * Updates the slected state of the option.
   * @stateChange option { {name: string, id: number, selected: boolean} }
   */
  changeSelectedState = () => {
    const { onOptionChange } = this.props;
    const tempOption = this.state.option;

    tempOption.selected = !tempOption.selected;

    this.setState(
      (state) => ({
        option: tempOption,
      }),
      () => {
        onOptionChange(tempOption);
      }
    );
  };

  render() {
    const { option } = this.props;

    return (
      <div
        className="form-check form-check-inline mx-2"
        style={{ cursor: 'pointer' }}
      >
        <input
          className="form-check-input"
          type="checkbox"
          id={option.id}
          checked={this.state.option.selected}
          onChange={this.changeSelectedState}
          style={{ cursor: 'pointer' }}
        />
        <label
          className="form-check-label"
          htmlFor={option.id}
          style={{ cursor: 'pointer' }}
        >
          {option.name}
        </label>
      </div>
    );
  }
}

export default FeedbackOption;
