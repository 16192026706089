import axios from 'axios';
import domain from '../../../index';
import FeedbackOptions from '../../../common/components/FeedbackOptions';
import React, { Component } from 'react';

/**
 * @brief For allowing the student to confgure a selected personal projects.
 */
class ProjectConfig extends Component {
  state = {
    feedbackOptions: [],
    configuring: this.props.project.configured,
  };

  /**
   * Get the feedback options from the server.
   * @stateChange feedbackOptions { {name: string, id: number, selected: bool}[] }
   * @note Method gets invoked right after component has been rendered.
   */
  componentDidMount = async () => {
    let response = await axios.get(`${domain.serverBase}/instructor/feedbackOptions`);

    let feedbackOptions = response.data;

    for(let [index, feedbackOption] of feedbackOptions.entries()) {
      feedbackOption.id = index;
      feedbackOption.selected = false;
    }

    this.setState({ feedbackOptions });
  };

  /**
   * Handle when a feedback option for a project 
   * has been selected or unselected.
   * @param { {name: string, id: number, selected: bool} } option
   * @stateChange feedbackOptions { {name: string, id: number, selected: bool}[] }
   */
  handleOptionSelectionChange = (option) => {
    const { project, onFeedbackOptionSelectionChange } = this.props;

    let feedbackOpts = this.state.feedbackOptions;

    for (const feedbackOpt of feedbackOpts) {
      if (feedbackOpt.id === option.id) {
        feedbackOpt.selected = option.selected;
        break;
      }
    }

    this.setState(
      (state) => ({
        feedbackOptions: feedbackOpts,
      }),
      () => {
        let selectedOptions = this.state.feedbackOptions.filter(
          (option) => option.selected
        );

        onFeedbackOptionSelectionChange(project.name, selectedOptions);
      }
    );

  };

  /**
   * Handle if the configuration state of the project has changed
   * @stateChange configuring {bool}
   */
  handleLeaveUnconfiguredChange = () => {
    const {onConfiguredStateChange } = this.props;

    this.setState(
      (state) => ({
        configuring: !this.state.configuring,
      }),
      () => {
        onConfiguredStateChange(this.state.configuring);
      }
    );
  };

  render() {
    const { project } = this.props;

    return (
      <div
        className="d-inline-flex flex-column border bg-white rounded p-2 mb-2"
        style={{ cursor: 'pointer' }}
      >

        {/*Fields for choosing whether to configure a project or leave it unconfigured (radio buttons)*/}
        <fieldset className="form-group mt-2 ml-3 mb-2">
          <div className="row" style={{paddingLeft: '0px'}}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name={project.name + 'gridRadios'}
                id={project.name + 'Leave Unconfigured'}
                value="option1"
                checked={!this.state.configuring}
                onChange={this.handleLeaveUnconfiguredChange}
                style={{ cursor: 'pointer' }}
              />
              <label
                className="form-check-label"
                htmlFor={project.name + 'Leave Unconfigured'}
                style={{ cursor: 'pointer' }}
              >
                Leave Unconfigured
              </label>
            </div>

            <div className="form-check ml-2">
              <input
                className="form-check-input"
                type="radio"
                name={project.name + 'gridRadios'}
                id={project.name + 'Configure'}
                value="option2"
                onChange={this.handleLeaveUnconfiguredChange}
                style={{ cursor: 'pointer' }}
              />
              <label
                className="form-check-label"
                htmlFor={project.name + 'Configure'}
                style={{ cursor: 'pointer' }}
              >
                Configure
              </label>
            </div>
          </div>
        </fieldset>

        {/*Show feedback options if the project has been chosen for configuration*/}
        {this.state.configuring && (
          <FeedbackOptions
            feedbackOptions={this.state.feedbackOptions}
            project={project}
            onOptionChange={this.handleOptionSelectionChange}
          />
        )}

      </div>
    );
  }
}

export default ProjectConfig;
