import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { Component } from 'react';
import styled from 'styled-components';

let myChart;

const GraphContainer = styled.div`
  width: 50vw;
  height: 52vh;
  background: white;
  padding: 30px 20px 10px 20px;
  border-radius: 10px;
`

const BackgroundColours = [
  'rgba(255, 99, 132, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 206, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(255, 159, 64, 0.2)',
  'rgba(255, 99, 132, 0.2)',
  'rgba(54, 162, 235, 0.2)',
  'rgba(255, 206, 86, 0.2)',
  'rgba(75, 192, 192, 0.2)',
  'rgba(153, 102, 255, 0.2)',
  'rgba(255, 159, 64, 0.2)'
]

const BorderColours = [
  'rgba(255,99,132,1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
  'rgba(255,99,132,1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)'
]

/**
 * @brief A pie chart that displays the runner data.
 *        Built to allow multiple forms
 */
export default class RunnerPieChart extends Component {
  chartRef = React.createRef();

  /**
   * Builds the pie chart when initially rendered.
   * @note Method gets invoked right after component has been rendered.
   */
  componentDidMount() {
    this.buildChart();
  }

  /**
   * Builds the pie chart when update.
   * @note Method gets invoked right after component has been updated.
   */
  componentDidUpdate() {
    this.buildChart();
  }

  /**
   * Build the pie chart
   */
  buildChart = () => {
    const { data, title } = this.props;
    const myChartRef = this.chartRef.current.getContext('2d');
    if (typeof myChart !== 'undefined') myChart.destroy();

    myChart = new Chart(myChartRef, {
      type: 'pie',
      data: {
        labels: data.labels,
        datasets: [{
          label: data.datasets.label,
          data: data.datasets[0].data,
          backgroundColor: BackgroundColours,
          borderColor: BorderColours,
        }]
      },
      plugins: [ChartDataLabels],
      options: {
        title: {
          display: true,
          text: title
        },
        tooltips: {
          enabled: true
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.forEach(data => {
                  sum += data;
              });
              let percentage = (value*100 / sum).toFixed(2)+"%";
              return percentage;
            },
            color: '#444444',
          }
        }
      }
    })
  }

  render() {
    const { chartId } = this.props;
    return (
      <GraphContainer>
        <canvas
          id={chartId}
          ref={this.chartRef}
        />
      </GraphContainer>
    )
  }
}
