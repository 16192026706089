import ProjectConfig from './ProjectConfig';
import React, { Component } from 'react';

/**
 * @brief the preview for the personal project to be configured.
 */
class UnconfiguredProjectPreview extends Component {
  render() {
    const {
      continueFromPreview,
      project,
      onEditProjectIdClick,
      onFeedbackOptionSelectionChange,
      onConfiguredStateChange,
      handleAddingProjectConfiguration
    } = this.props;

    return (
      <div className="d-inline-flex flex-column border bg-white rounded p-2"> 

        {Object.keys(project).length === 0 && (
          <div
            className="mb-2"
            style={{ color: 'red' }}
            data-testid="emptyProjectOutput"
          >
            <b>
              <div>The project corresponding to the Gitlab Project ID you provided cannot be configured.</div>
              <div>This project may have already been configured, or you do not have access to the course on gitlab.</div>
              <div>Please ensure you have entered the correct Gitlab Project ID.</div>
            </b>

            <button
              className="btn btn-secondary"
              onClick={onEditProjectIdClick}
              data-testid="editGitlabProjecttIdButton"
            >
              Edit Gitlab Project ID 
            </button> 
          </div>
          
        )}

        {Object.keys(project).length > 0 && (
          <>
            <div data-testid="preview">
            {continueFromPreview === false && (
              <div data-testid="previewTitle">
                <h5>Preview</h5>
              </div>
            )}

            <div className="mb-2" data-testid="previewName">
              <b>Project Name: </b>
              {project.name}
            </div>

            <div
              className="d-inline-flex flex-row mb-2"
              data-testid="previewURL"
            >
              <b className="mr-1">Project URL: </b>
              <a
                href={project.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {project.url}
              </a>
            </div>
          </div>

          <div>
            <div>
              <ProjectConfig
                onFeedbackOptionSelectionChange={onFeedbackOptionSelectionChange}
                onConfiguredStateChange={onConfiguredStateChange}
                project={project}
              />
            </div>
          </div>

          <div>
            <button
              className="btn btn-primary"
              style={{ marginRight: '10px'}}
              onClick={handleAddingProjectConfiguration}
            >
              Configure Project
            </button>

            <button
              className="btn btn-secondary"
              onClick={onEditProjectIdClick}
              data-testid="editGitlabProjecttIdButton"
           >
            Edit Gitlab Project ID 
            </button>
          </div>    
        </>
        )}

      </div>
    );
  }
}

export default UnconfiguredProjectPreview;
