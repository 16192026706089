import React, { Component } from 'react';
import styled from 'styled-components';

const FeedbackItemAttribute = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const AttributeName = styled.span`
  text-transform: capitalize;
  font-size: 17px;
  font-weight: bold;
`;

/**
 * @brief A collapsable containing a single FeedbackItem (a single CI pipeline test)
 *        from a given array of FeedbackItems for the current commit.
 */
class FeedbackItem extends Component {
  state = {
    expanded: this.props.expanded,
  };

  /**
   * Updates the feedbackItem expanded state.
   * @param prevProps
   * @param prevState
   * @stateChange expanded {bool}
   * @note Method gets invoked right after component has been updated.
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.expanded !== prevProps.expanded || this.props.expanded !== prevState.expanded) {
      this.setState({ expanded: this.props.expanded });
    }
  }

  /**
   * Updates the expanded state.
   * @stateChange expanded {bool}
   */
  toggleCollapse = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  /**
   * Add spaces to feedback item attribute key for display purposes.
   * @return attributeKey {string}  
   */
  addSpacesToCamelCase = (attributeKey) => {
    return attributeKey.replace(/([A-Z])/g, ' $1');
  };

  render() {
    const { feedbackItem, count } = this.props;
    
    return (
      <div className="card">
        <div
          className="card-header p-2"
          id="feedbackHeading"
          style={{ backgroundColor: 'rgb(239, 241, 245)' }}
        >
          <h2 className="mb-0">
            <button
              className="btn btn-link btn-block text-left"
              type="button"
              data-toggle="collapse"
              data-target="#feedbackCollapse"
              aria-expanded="true"
              aria-controls="feedbackCollapse"
              onClick={() => this.toggleCollapse()}
            >
              <div className="d-flex flex-row align-items-center justify-content-between">
                {count}) {feedbackItem.name}
                {this.state.expanded === true ? (
                  <svg
                    width="1.25em"
                    height="1.25em"
                    viewBox="0 0 16 16"
                    className="bi bi-chevron-up"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
                    />
                  </svg>
                ) : (
                  <svg
                    width="1.25em"
                    height="1.25em"
                    viewBox="0 0 16 16"
                    className="bi bi-chevron-down"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                )}
              </div>
            </button>
          </h2>
        </div>

        <div
          id="feedbackCollapse"
          className={this.state.expanded ? 'collapse show' : 'collapse hide'}
          aria-labelledby="feedbackHeading"
          data-parent="#feedbackAccordian"
        >
          <div className="card-body py-0">
            {Object.keys(feedbackItem).map(
              (attributeKey, i) =>
                attributeKey !== 'name' && (
                  <FeedbackItemAttribute key={i}>
                    <AttributeName>
                      {this.addSpacesToCamelCase(attributeKey)}:{' '}
                    </AttributeName>
                    {Array.isArray(feedbackItem[attributeKey]) ? (
                      <div>
                        {feedbackItem[attributeKey].length === 0
                          ? 'N/A'
                          : feedbackItem[attributeKey].map((itemVal, j) => (
                              <div key={itemVal} className="mb-2">
                                {j + 1}) {itemVal}
                              </div>
                            ))}
                      </div>
                    ) : (
                      <div>{feedbackItem[attributeKey]}</div>
                    )}
                  </FeedbackItemAttribute>
                )
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default FeedbackItem;
