import axios from 'axios';
import Cookies from 'js-cookie';
import CourseList from '../components/allCoursesPage/CourseList';
import domain from '../../index.js';
import Loading from '../components/allCoursesPage/Loading';
import React, { Component } from 'react';
import TopMenuBar from '../../common/components/TopMenuBar';

/**
 * @brief Page to display all configured courses to the instructor in a table.
 *        Allows Instructor to configure a new course.
 */
class AllCoursesPage extends Component {
  state = { courses: [], loading: true };

  /**
   * Fetch configured courses from gitlab
   * @stateChange courses {id: number, name: string}
   * @stateChange loading {bool}
   * @note Method gets invoked right after component has been rendered.
   */
  async componentDidMount() {
    try {
      let response = await axios.get(`${domain.serverBase}/instructor/groups/configured?token=` + 
                                     Cookies.get('token'));

      this.setState({ courses: response.data });
    } catch (err) {
      this.setState({ courses: err.response.data });
    } finally {
      this.setState({ loading: false });
    }
  }

  /**
   * When the button to add a new course is clicked, go to the page which 
   * will handle the process to add a new course to IFS.
   */
  handleNewCourseNav = () => {
    this.props.history.push('/instructor/courses/new-course');
  };

  /**
   * When logout button is clicked, return to the login page.
   * @note revoking the cookie doensn't seem to work as we want it to...
   */
  handleLogoutButton = () => {
    Cookies.remove('token');
    this.props.history.push('/');
  };

  render() {
    if (this.state.loading) {
      return <Loading />;
    } else {
      return (
        <>
          <TopMenuBar
            activeLink="Courses"
            onLogoutClick={this.handleLogoutButton}
            isInstructor={true}
          />
          <CourseList
            courses={this.state.courses}
            onAddNewClick={this.handleNewCourseNav}
          />
        </>
      );
    }
  }
}

export default AllCoursesPage;
