import App from './App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';

if(process.env.REACT_APP_NODE_ENV !== "production") {
  require('dotenv').config();
  console.log("client in dev mode");
}

//TODO this section needs a conditional to deal with PROD vs DEV
//setting up hostname and client key
const domain = {};
export default domain;
domain.serverBase = process.env.REACT_APP_BASE_SERVER_URL;  
domain.clientID = process.env.REACT_APP_CLIENTID;
Object.freeze(domain);  // turns object into a constant that can be globally imported

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.body.appendChild(document.createElement("div"))
);
