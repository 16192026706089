import axios from 'axios';
import Cookies from 'js-cookie';
import domain from '../../index';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 1rem;
  margin-right: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  color: #222222;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 38px;
`;

/**
 * @brief The top menu bar at the top of the page. 
 *        Should be visible on all instructor pages in the
 *        app.
 * @note This returns the menu bar for both instructor and 
 *       student based on the 'isInstructor' variable
 */
class TopMenuBar extends Component {
  state = { name: '' };

  /**
   * Gets the name of the person logged in and updates the state accordingly
   * @stateChange name {string}
   */
  async componentDidMount() {
    let response = await axios.get(`${domain.serverBase}/user/name?token=` + 
                                   Cookies.get('token'));
    this.setState({ name: response.data, })
  }

  /**
   * Check if the nav-item should be active or not, based on the active prop and the corresponding link
   * @param {String} linkName
   * @note used for instructor menu bar
   */
     getClassNameForLink = (linkName) => {
      const { activeLink } = this.props;
      if (linkName === activeLink) {
        return 'nav-item active';
      }
      return 'nav-item';
    };

  render() {
    const { onLogoutClick, isInstructor } = this.props;

    if(!isInstructor) {
      return (
        <MenuContainer>
          <Link className="navbar-brand" style={{ color:'#000000'}} to="/student">
            Immediate Student Feedback
          </Link>
          <ButtonContainer>
            <h5 className="mt-2">{this.state.name}</h5>
            <button className="btn btn-danger ml-3" onClick={onLogoutClick}>
              Logout
            </button>
          </ButtonContainer>
        </MenuContainer>
      );
    }

    if(isInstructor) {
      return (
        <nav
          className="navbar navbar-expand navbar-light"
          style={{ backgroundColor: '#b3e6b3' }}
        >
          <Link className="navbar-brand" to="/instructor/courses">
            Immediate Student Feedback
          </Link>
  
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
            <li className={this.getClassNameForLink('Courses')}>
              <Link className="nav-link" to="/instructor/courses">
                Courses
              </Link>
            </li>
          </ul>
  
          <span className="navbar-brand">{this.state.name}</span>
  
          <button className="btn btn-danger ml-1" onClick={onLogoutClick}>
            Logout
          </button>
        </nav>
      );
    }
  }
}

TopMenuBar.propTypes = {
  onLogoutClick: PropTypes.func.isRequired,
};

export default TopMenuBar;
