import React, { Component } from 'react';
import SelectMenu from './SelectMenu';

/**
 * @brief The selection (dropdown) menu for selecting projects that contains
 *        branches, students and feedback the instructor wishes to view
 */
class ProjectSelect extends Component {
  render() {
    const { selectedProject, projects, onProjectChange } = this.props;

    return (
      <SelectMenu
        selected={selectedProject}
        data={projects}
        onChange={onProjectChange}
      />
    );
  }
}

export default ProjectSelect;
