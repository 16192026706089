import React, { Component } from 'react';

/**
 * @brief modal to confrm whether the student would like to submit their
 *        new project configuration.
 */
class ProjectConfigConfirmationModal extends Component {
  render() {
    const { onCancelConfig, onSubmitConfig } = this.props;

    return (
      <>
        <div className="modal-backdrop fade show" style={{ zIndex: '1' }} />
        <div
          className="modal fade show"
          style={{
            display: 'block',
            zIndex: '2',
          }}
          id="staticBackdrop"
          data-backdrop="static"
          data-keyboard="false"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Confirm Configuration
                </h5>

                {/*Button to close the modal*/}
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={onCancelConfig}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                The configuration of the project cannot be edited once submitted.
                Are you sure you would like to submit this configuration?
              </div>

              <div className="modal-footer">
                {/*Button to cancel submission of configuration*/}
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onCancelConfig}
                >
                  Continue Editing
                </button>

                {/*Button to confirm submission of configuration*/}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={onSubmitConfig}
                >
                  Submit Configuration
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProjectConfigConfirmationModal;
